import React from 'react';
import Layout from '../../components/Layout';
import Seo from '../../components/Seo';
import Title from '../../components/Title';
import './style.sass';

export default props => {
  return (
    <Layout>
      <Seo
        title="夢占いについて"
        description="マダムたぬきの夢占いについて"
        href={props.location.href}
      />
      <div className="overview">
        <Title>夢占いについて</Title>
        <div className="overview__description">
          <p>
            目が覚めた時、夢の中の不思議な出来事に気持ちが引っ張られることもありませんか？
          </p>
          <p>怖い夢は必ず悪いというわけではありません。</p>
          <p>夢はあなたに隠れたメッセージを伝えようとしています。</p>
          <p>
            夢はあなたの深層心理と密接に紐づいていたり、正夢のような不思議な出来事もあります。
          </p>
          <p>
            夢占いは、あなたに届く隠れたメッセージを解き明かす為にあります。
          </p>
          <p>
            古来中国では夢占いで占った吉凶を戦に役立てたり、古代ギリシャの夢占いの研究文献が見つかったり、近年でも学者たちによって夢と心理学を紐づける研究がされています。これまで世界のあちこちで夢が投げかけるメッセージを受け取ろうとしてきました。
          </p>
          <p>
            「正夢」や「初夢」なんて言葉があるように、日本でもゲン担ぎや迷信も色濃く残っていますね。
          </p>
          <p>
            ここではマダムたぬきが先人たちの残した夢占いを基盤にミックスし、オリジナルな占いとしてメッセージを紐解くお手伝いをします。
          </p>
        </div>
      </div>
    </Layout>
  );
};
